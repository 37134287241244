import React, { useCallback } from 'react'
import axios from 'axios'
import TextareaAutosize from 'react-textarea-autosize'
import Attach from '../assets/icons/attach.inline.svg'
import { useDropzone } from 'react-dropzone'
import Close from '../assets/icons/close.inline.svg'
import * as validator from 'validator'
import * as qs from 'query-string'
import emailjs from 'emailjs-com'
import {} from 'emailjs-com/src/index'
// const Dropzone = React.memo(function Dropzone(props) {
//   const [filesState, setFiles] = React.useState([])
//   React.useEffect(() => {
//     props.callback(filesState)
//   }, [filesState])
//   const onDrop = useCallback(acceptedFiles => {
//     acceptedFiles.forEach(file => {
//       const reader = new FileReader()

//       reader.onabort = () => console.log('file reading was aborted')
//       reader.onerror = () => console.log('file reading has failed')
//       reader.onload = () => {
//         const binaryStr = reader.result
//       }
//       reader.readAsArrayBuffer(file)
//       setFiles(c => [...c, ...acceptedFiles])
//     })
//   }, [])
//   const { getRootProps, getInputProps, open } = useDropzone({
//     onDrop,
//   })

//   const remove = (e, id) => {
//     e.preventDefault()
//     setFiles(
//       filesState.filter(function (fid, i) {
//         return i !== id
//       }),
//     )
//   }
//   return (
//     <div className={'file-wrapper'}>
//       <div {...getRootProps()}>
//         <input {...getInputProps()} name={props.name} />
//         <Attach className="file-zone-svg" />
//       </div>
//       <div
//         style={{
//           textAlign: 'left',
//           fontSize: 14,
//           color: '#1B1D25',
//           zIndex: 5,
//           position: 'relative',
//           marginTop: 20,
//         }}>
//         {filesState.map((file, i) => (
//           <span
//             key={i}
//             style={{
//               marginRight: 14,
//               display: 'inline-flex',
//               alignItems: 'center',
//             }}>
//             {file.path}
//             <Close
//               style={{ marginLeft: 8, cursor: 'pointer' }}
//               onClick={e => {
//                 remove(e, i)
//               }}
//             />
//           </span>
//         ))}
//       </div>
//     </div>
//   )
// })
export const HireUs = props => {
  const [feedbackMsg, setfeedbackMsg] = React.useState(null)
  const [isValidate, setIsValidate] = React.useState(false)
  const formRef = React.useRef(null)
  const [formState, setformState] = React.useState({
    name: null,
    email: null,
    description: null,
    files: null,
  })
  const handleChangeForm = e => {
    setformState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }
  const [validatorState, setValidator] = React.useState({
    name: { value: false, message: '' },
    email: { value: false, message: '' },
  })

  const clear = name => {
    setValidator(c => ({ ...c, [name]: { value: false, message: '' } }))
  }

  const validate = () => {
    let isInValid = false
    if (!validator.isEmail(formState.email || '')) {
      setValidator(c => ({
        ...c,
        email: { value: true, message: 'Email is not valid' },
      }))
      isInValid = true
    } else {
      clear('email')
    }
    if (
      !validator.isByteLength(formState.name || '', { min: 3, max: undefined })
    ) {
      setValidator(c => ({
        ...c,
        name: { value: true, message: 'Min length 3' },
      }))
      isInValid = true
    } else {
      clear('name')
    }
    return !isInValid
  }
  React.useEffect(() => {
    setIsValidate()
  }, [validatorState])
  async function handleSubmit(event) {
    event.preventDefault()
    const isValid = validate()
    if (isValid) {
      const formData = new FormData()
      Object.keys(formState).map(key => {
        formData.append(key, formState[key])
      })
      formState.files?.map((f, i) => {
        formData.append(`file-${1}`, f)
      })
      formData.append('form-name', 'hireus')
      formData.append('service_id', 'hello@axicube.io')
      formData.append('template_id', 'template_zm1524k')
      formData.append('user_id', 'user_VT1ITYE8nOGxGI4Z2aK6E')
      const axiosOptions = {
        url: 'https://api.emailjs.com/api/v1.0/email/send-form',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      }
      await axios(axiosOptions)
        .then(() => {
          setfeedbackMsg('Form submitted successfully!')
          this.domRef.current.reset()
          setformState({
            name: null,
            email: null,
            description: null,
          })
        })
        .catch(err => {})
    }
    return
  }
  return feedbackMsg ? (
    <div className="form-succesess-msg">{feedbackMsg}</div>
  ) : (
    <form
      encType="multipart/form-data"
      name="hireus"
      method="POST"
      onSubmit={handleSubmit}
      onChange={handleChangeForm}
      noValidate
      ref={formRef}>
      <div className="group">
        <input
          type="text"
          name="name"
          required
          id="name"
          className={validatorState.name.value ? 'error' : ''}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label htmlFor="name">Your name</label>
        <span className="error-msg">{validatorState.name.message}</span>
      </div>
      <div className="group">
        <input
          type="text"
          name="email"
          required
          id="email"
          className={validatorState.email.value ? 'error' : ''}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label htmlFor="email">E-mail</label>
        <span className="error-msg">{validatorState.email.message}</span>
      </div>
      <div className="group text-area">
        <TextareaAutosize
          required
          name="description"
          id="description"
          className={validatorState.description ? 'error' : ''}
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        <label htmlFor="description">Tell about your idea (optinal)</label>
        {/* <Dropzone
          name="file"
          callback={files =>
            setformState({
              ...formState,
              files: files,
            })
          }
        ></Dropzone> */}
      </div>

      <button className="btn btn-submit" type="submit">
        Send
      </button>
    </form>
  )
}
