import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'
import ChevronLeft from 'assets/icons/chevronLeft.inline.svg'
import { Modal } from 'components/modal'
import { HireUs } from 'components/hireus'
const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const [modalIsOpen, setmodalIsOpen] = React.useState(false)

  const posts = data.allMarkdownRemark.nodes
  const [seeAlsoLimit, setSeeAlsoLimit] = React.useState(3)
  React.useEffect(() => {
    if (window.innerWidth < 769) {
      setSeeAlsoLimit(2)
    }
  }, [])
  return (
    <Layout location={location} className="article">
      <SEO
        title={post.frontmatter.meta_title || post.frontmatter.title}
        description={
          post.frontmatter.meta_description || post.frontmatter.description
        }
        location={location.href}
        image_url={post.frontmatter.image?.publicURL}
      />
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <Link to="/blog" className="btn btn-back">
              <ChevronLeft />
              Back to blog
            </Link>
          </div>
        </div>
      </div>
      <article
        className="article-wrapper"
        itemScope
        itemType="http://schema.org/Article">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12">
              <div className="article-category">
                {post.frontmatter.categories}
              </div>
              <h1 itemProp="headline" className="article-title">
                {post.frontmatter.title}
              </h1>
              <div className="article-details">
                <div className="article-author">
                  Article by {post.frontmatter.author}
                </div>
                <span className="splitter"></span>
                <div className="article-date">{post.frontmatter.date}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div
                className="cover"
                style={{
                  backgroundImage: `url(${post.frontmatter.image?.publicURL})`,
                }}></div>
            </div>
          </div>
          <div className="row justify-content-center articleBody">
            <div
              className="col-xxl-8 col-xl-8 article-content"
              itemProp="articleBody"
              dangerouslySetInnerHTML={{ __html: post.html }}></div>
          </div>
        </div>
      </article>
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-8 text-center">
              <h2>{post.frontmatter.footer_title}</h2>
              <button
                className="btn btn-fill btn-medium btn-load-more"
                onClick={() => setmodalIsOpen(!modalIsOpen)}>
                {post.frontmatter.footer_btn_text}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="posts">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <h2 className="text-center global-title">See also</h2>
            </div>
          </div>
          <div className="row">
            {posts?.slice(0, seeAlsoLimit).map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <div
                  className="col-xxl-4 col-xl-4 col-md-6"
                  key={post.fields.slug}>
                  <Link to={`/blog${post.fields.slug}`} className="post">
                    <div className="post-cover">
                      <img src={post.frontmatter.image?.publicURL} alt="" />
                    </div>
                    <div className="post-category">
                      {post.frontmatter.categories}
                    </div>
                    <h2 className="post-title">{title}</h2>
                    <div className="post-author">
                      by {post.frontmatter.author}
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={() => setmodalIsOpen(false)}>
        <HireUs />
      </Modal>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        categories
        meta_description
        meta_title
        footer_title
        footer_btn_text
        image {
          publicURL
        }
        author
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          categories
          image {
            publicURL
          }
          author
        }
      }
    }
  }
`
