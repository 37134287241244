import React, { useEffect } from 'react'
import Close from '../assets/icons/close.inline.svg'

export const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    isOpen
      ? (document.querySelector('body').style.overflow = 'hidden')
      : (document.querySelector('body').style.overflow = 'unset')
  }, [isOpen])

  return isOpen ? (
    <div className="modal">
      <div className="modal-bg" onClick={() => onClose()}></div>
      <div className="modal-wrapper">
        <div className="modal-close" onClick={() => onClose()}>
          <Close />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-10">
              <h3 className="global-title">Got a project in mind?</h3>
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-9">
              <div className="sub-title text-center">
                We can help! Leave us your email, so we could contact you,
                examine your product, and offer some advice in UX design.
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-xl- col-md-10 col-lg-8 col-lg-8 col-md-8">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
